import { Id } from '@restworld/data-services/src/lib/utils/common_types';
import { Organization } from '@restworld/utility-types';
import { ENDPOINTS } from 'constants/api';
import useAuth from 'hooks/useAuth';
import { useMemo } from 'react';
import { Service } from 'service/baseService';
import dynamicAxios from 'utils/axios';

export type FlexibleSlot = {
  id: number;
  organization_id?: string;
  organization?: Organization;
  activated_at?: string;
  expires_at?: string;
  status?: string;
  price?: number;
  cancel_at_period_end?: boolean;
  stripe_invoice_id?: string;

  inserted_at: string;
  updated_at: string;
};

export type IndexFlexibleSlot = {
  hits: number;
  data: FlexibleSlot[];
};

class ServiceAccounts extends Service {
  me() {
    return dynamicAxios(this.jwt).get(ENDPOINTS.accounts.me);
  }

  fetchUserType({ email }: { email?: string }) {
    return dynamicAxios(this.jwt).get<{ type: string }>(`${ENDPOINTS.accounts.root}/user_type`, {
      params: { email }
    });
  }

  migrateUser({ email, toType }: { email?: string; toType?: string }) {
    return dynamicAxios(this.jwt).post<string>(`${ENDPOINTS.accounts.root}/migrate_user`, {
      email,
      to_type: toType
    });
  }

  login(email: string, password: string) {
    return dynamicAxios(this.jwt).post(ENDPOINTS.accounts.login, { email, password });
  }

  createSetupCheckoutSession({
    employerId,
    companyId
  }: {
    employerId?: string;
    companyId?: string;
  }) {
    return dynamicAxios(this.jwt).get(ENDPOINTS.accounts.setup_checkout_session, {
      params: { employer_id: employerId, company_id: companyId }
    });
  }

  getIntercomContactId({ email }: { email?: string }) {
    return dynamicAxios(this.jwt).get<Id>(ENDPOINTS.accounts.get_intercom_contact_id, {
      params: { email }
    });
  }

  createEmployerUserContact(data: any) {
    return dynamicAxios(this.jwt).post<Id>(ENDPOINTS.accounts.employerUserContact, data);
  }

  updateEmployerUserContact(data: any) {
    return dynamicAxios(this.jwt).patch<Id>(ENDPOINTS.accounts.employerUserContact, data);
  }

  getEmployerUserContactAccessLink(eucId?: string) {
    return dynamicAxios(this.jwt).get(ENDPOINTS.accounts.employerUserContactAccessLink, {
      params: { user_id: eucId }
    });
  }

  listFlexibleSlots({
    limit,
    offset,
    organizationId
  }: {
    limit: number;
    offset: number;
    organizationId?: string;
  }) {
    return dynamicAxios(this.jwt).get<IndexFlexibleSlot>(ENDPOINTS.accounts.flexibleSlots, {
      params: { limit, offset, organization_id: organizationId }
    });
  }

  flexibleSlotInstallmentPayment({
    companyId,
    slots,
    discount
  }: {
    companyId?: string;
    slots?: number;
    discount?: string;
  }) {
    return dynamicAxios(this.jwt).post<string>(
      `${ENDPOINTS.accounts.flexibleSlots}/pay_in_installments`,
      {
        company_id: companyId,
        slots,
        type: 'percentage',
        discount
      }
    );
  }

  issueFreeSlot({ companyId }: { companyId?: string }) {
    return dynamicAxios(this.jwt).post<string>(ENDPOINTS.accounts.freeSlot, {
      company_id: companyId
    });
  }

  activateFlexibleSlot({ organizationId }: { organizationId?: string }) {
    return dynamicAxios(this.jwt).post<string>(ENDPOINTS.accounts.activateFlexibleSlot, {
      organization_id: organizationId
    });
  }

  hasActiveFlexibleSlot({ organizationId }: { organizationId?: string }) {
    return dynamicAxios(this.jwt).get<boolean>(ENDPOINTS.accounts.hasActiveFlexibleSlot, {
      params: { organization_id: organizationId }
    });
  }

  deleteSecondaryContact({ id }: { id?: number }) {
    return dynamicAxios(this.jwt).delete<string>(`${ENDPOINTS.accounts.secondaryContacts}/${id}`);
  }
}

const useService = () => {
  const { jwt } = useAuth();
  return useMemo(() => new ServiceAccounts(jwt), [jwt]);
};
export default useService;

export const createAccountService = (jwt?: string) => new ServiceAccounts(jwt);

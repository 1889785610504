import { Card, Stack, CardContent, Skeleton, Tooltip } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useSearchParams } from 'react-router-dom';

import CropLongText from 'components/CropLongText';
import { EmployerUserContact } from '@restworld/utility-types';

import { useOrganizationsService } from '@restworld/data-services';
import { OrganizationUpdated } from 'pages/showOrganization/global-state';
import { useEffect, useState } from 'react';
import { IconButton } from '@mui/material';
import { FormatListBulletedOutlined } from '@mui/icons-material';
import OrganizationStructuredNoteDialog from 'components/OrganizationStructuredNoteDialog';

const OrganizationDetails = () => {
  const [searchParams] = useSearchParams();
  const organizationService = useOrganizationsService();
  const organizationId = searchParams.get('organizationId');
  const [isStructuredNoteDialogOpen, setIsStructuredNoteDialogOpen] = useState<boolean>(false);

  const {
    data: organization,
    isFetching,
    refetch
  } = useQuery({
    queryKey: [`organization_${organizationId}`],
    queryFn: async () => {
      return (
        await organizationService.showOrganization(
          organizationId as string,
          'LAB_RestauratForm_OrganizationDetails'
        )
      ).data;
    },
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    enabled: typeof organizationId === 'string'
  });

  useEffect(() => {
    refetch();
  }, [organizationId, refetch]);

  return (
    <>
      {isFetching ? (
        <Card variant="outlined" sx={{ minWidth: '250px' }}>
          <CardContent>
            <Skeleton variant="text" width="100%" height={30} />
            <Skeleton variant="text" width="100%" height={30} />
            <Skeleton variant="text" width="100%" height={30} />
            <Skeleton variant="text" width="100%" height={30} />
          </CardContent>
        </Card>
      ) : (
        organization && (
          <Card variant="outlined">
            <OrganizationStructuredNoteDialog
              isOpen={isStructuredNoteDialogOpen}
              onClose={() => setIsStructuredNoteDialogOpen(false)}
              inputStructuredNote={(organization as OrganizationUpdated)?.structured_note}
              viewMode={true}
              refresh={refetch}
              organizationId={organizationId as string}
              insertMode={!(organization as OrganizationUpdated)?.structured_note}
            />
            <CardContent>
              <Stack spacing={2}>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <CropLongText
                    label={organization?.name}
                    variant="body1"
                    fontWeight={900}
                    visibleLength={40}
                  />
                  <Tooltip title="Onboarding notes">
                    <IconButton onClick={() => setIsStructuredNoteDialogOpen(true)}>
                      <FormatListBulletedOutlined />
                    </IconButton>
                  </Tooltip>
                </Stack>
                <Stack>
                  {organization?.employer_user_contacts?.map((euc: EmployerUserContact) => {
                    return (
                      <CropLongText
                        key={euc?.user_id}
                        label={
                          euc?.user?.name
                            ? `${euc?.user?.name} - ${euc?.user?.email}`
                            : `${euc?.user?.email}`
                        }
                        variant="subtitle2"
                        visibleLength={40}
                      />
                    );
                  })}
                </Stack>
              </Stack>
            </CardContent>
          </Card>
        )
      )}
    </>
  );
};
export default OrganizationDetails;
